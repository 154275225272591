import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';
import NextProgress from 'next-progress';
import { Provider } from 'react-redux';

import { LayoutResolver } from 'components/Layout';

import '../styles/globals.css';

import { TrackingProvider } from 'context/TrackingContext';
import { store } from 'store';

import client from '../api/client';

const TITLE = 'Endless Commerce';

function App(props) {
  return (
    <>
      <Head>
        <title>{TITLE}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <TrackingProvider>
            <NextProgress delay={300} options={{ showSpinner: false }} />
            <LayoutResolver {...props} />
          </TrackingProvider>
        </Provider>
      </ApolloProvider>
    </>
  );
}

export default App;
