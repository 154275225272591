import { TrackingService } from 'types/analytics';

class ClarityService implements TrackingService {
  isClarityAvailable = () => {
    return (
      typeof window !== 'undefined' && typeof window.clarity !== 'undefined'
    );
  };

  trackEvent = (eventName: string) => {
    if (this.isClarityAvailable()) {
      window.clarity('event', eventName);
      console.info(`Tracking event '${eventName}' Fired`);
    }
  };

  setUser = (userId: string | undefined) => {
    if (this.isClarityAvailable()) {
      window.clarity('identify', userId);
      console.info(`setUser ${userId}`);
    }
  };
}

export default ClarityService;
