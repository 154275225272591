import { ErrorMessage, Field } from 'formik';
import { isNil } from 'ramda';
import { FocusEvent, FunctionComponent } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import styles from './styles.module.css';

export enum InputTheme {
  MATERIAL,
  CUSTOM,
}

interface InputTextProps extends NumericFormatProps {
  title?: string;
  name: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  theme?: InputTheme;
  placeholder?: string;
  prefix?: string;
  value?: string;
  isNumeric?: boolean;
  hasArrowControls?: boolean;
  handleUpArrowClick?(): void;
  handleDownArrowClick?(): void;
  onBlur?(event: FocusEvent<HTMLInputElement, Element>): void;
}

const CustomInput = ({ isNumeric, ...props }) => {
  const Component = isNumeric ? NumericFormat : 'input';
  return (
    <Component
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={false}
      autoComplete="off"
      valueIsNumericString={true}
      {...props}
    />
  );
};

const InputText: FunctionComponent<InputTextProps> = ({
  title,
  name,
  disabled,
  readonly = false,
  isNumeric = false,
  hasArrowControls = false,
  theme = InputTheme.MATERIAL,
  className = '',
  placeholder = '',
  prefix = '',
  value,
  onBlur,
  handleUpArrowClick,
  handleDownArrowClick,
  ...props
}) => {
  const themeClass = {
    [InputTheme.MATERIAL]: {
      input: styles.textBox,
      bar: styles.bar,
      tag: styles.tag,
      error: styles.invalid,
    },
    [InputTheme.CUSTOM]: {
      input:
        'shadow-bs-mvs-input border-mvs-border-lighter leading-none text-body-14 focus:border-mvs-border-main focus:outline-none',
      bar: '',
      tag: '',
      error: 'border-solid border-mvs-border-negative text-mvs-text-negative',
    },
  };

  const currentThemeStyle = themeClass[theme];

  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <div className={`relative ${disabled ? 'opacity-60' : ''}`}>
          {theme === InputTheme.CUSTOM && (
            <span
              className={`text-sm font-aeonik text-body-14 ${
                meta.error && meta.touched
                  ? 'text-mvs-text-negative'
                  : 'text-mvs-text-secondary'
              }`}
            >
              {title}
            </span>
          )}

          <div className="flex justify-center items-center">
            <CustomInput
              isNumeric={isNumeric}
              {...field}
              className={`${
                meta.touched && meta.error ? currentThemeStyle.error : ''
              } ${currentThemeStyle.input} ${className} ${
                hasArrowControls ? 'h-[50px]' : ''
              } font-aeonik`}
              disabled={disabled}
              prefix={prefix}
              readOnly={readonly}
              autoComplete="off"
              onBlur={e => {
                form.handleBlur(e);
                onBlur && onBlur(e);
              }}
              onChange={e => {
                if (isNumeric) {
                  const valueWithoutPrefix = e.target.value?.replace(
                    prefix,
                    ''
                  );
                  form.setFieldValue(name, Number(valueWithoutPrefix));
                  return;
                }
                form.setFieldValue(name, e.target.value);
              }}
              placeholder={placeholder}
              required
              value={!isNil(field.value) ? field.value : value}
              {...props}
            />
            {hasArrowControls && (
              <div className="flex h-[50px] ml-[-1px] flex-col border border-l-transparent rounded rounded-l-none py-[1px] shadow-bs-mvs-input border-mvs-border-lighter">
                <button
                  className="flex items-center justify-center fill-current text-neutral-80 px-2 border-b border-mvs-border-lighter select-none"
                  type="button"
                  onClick={() => handleUpArrowClick && handleUpArrowClick()}
                >
                  +
                </button>
                <button
                  className={`flex items-center justify-center fill-current text-neutral-80 px-2 select-none ${
                    field.value === 0 ? 'opacity-20 pointer-events-none' : ''
                  }`}
                  type="button"
                  onClick={() => handleDownArrowClick && handleDownArrowClick()}
                >
                  -
                </button>
              </div>
            )}
          </div>

          {theme === InputTheme.MATERIAL && (
            <>
              <span
                className={`${
                  meta.touched && meta.error ? currentThemeStyle.error : ''
                } ${currentThemeStyle.bar}`}
              ></span>
              <label className={`${currentThemeStyle.tag} font-aeonik`}>
                {title}
              </label>
              <ErrorMessage
                name={name}
                component="span"
                className={`${currentThemeStyle.error} block text-xs text-danger-40`}
              />
            </>
          )}
          {theme === InputTheme.CUSTOM && meta.touched && meta.error && (
            <span
              className={`${currentThemeStyle.error} text-xs text-mvs-text-negative`}
            >
              {meta.error}
            </span>
          )}
        </div>
      )}
    </Field>
  );
};

export default InputText;
