import { gql } from '@apollo/client';

interface Auth {
  loginIdentifier: string;
  loginIdentifierType: string;
  password: string;
}

interface Credentials {
  email: string;
  password: string;
}

export const authVariables = (credentials: Credentials): Auth => {
  return {
    loginIdentifierType: 'EMAIL',
    loginIdentifier: credentials.email,
    password: credentials.password,
  };
};

export const AUTH = gql`
  mutation SignIn(
    $loginIdentifier: String!
    $loginIdentifierType: LoginIdentifierTypesEnum
    $password: String!
  ) {
    signIn(
      input: {
        loginIdentifier: $loginIdentifier
        loginIdentifierType: $loginIdentifierType
        password: $password
      }
    ) {
      token
      tokenExpiration
      user {
        party {
          id
          details {
            ... on PersonDetails {
              firstName
              lastName
              avatar
            }
          }
          user {
            primaryEmail
          }
          roles {
            id
            name
          }
          humanizedRelationships
        }
      }
    }
  }
`;

export const SESSION = gql`
  query me {
    me {
      createdAt
      firstName
      lastName
      primaryEmail
      verified
      party {
        id
        details {
          ... on PersonDetails {
            firstName
            lastName
            avatar
          }
        }
        user {
          primaryEmail
        }
        roles {
          id
          name
        }
        humanizedRelationships
      }
    }
  }
`;

export const CLOSE_AUTH = gql`
  mutation signOut($input: SignOutInput!) {
    signOut(input: $input) {
      status
      message
    }
  }
`;

export const ROTATE_SESION = gql`
  mutation rotateSessionToken($input: RotateSessionTokenInput!) {
    rotateSessionToken(input: $input) {
      secondsLeft
      token
      validUntil
    }
  }
`;
