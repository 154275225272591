import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { SESSION } from 'api/session/auth';

import { useTracking } from './useTracking';

const DEFAULT_AVATAR_URL =
  'https://cdn.dev.mvnifest.co/attachments/User/avatar.png';

const useUserData = () => {
  const [currentUser, setCurrentUser] = useState(null);

  const { setUser } = useTracking();
  const { data: sessionResponse } = useQuery(SESSION);

  useEffect(() => {
    if (sessionResponse) {
      const {
        party: { details },
        ...user
      } = sessionResponse.me;

      const formatUser = {
        username: user?.firstName,
        email: user?.primaryEmail,
        src: details.avatar || DEFAULT_AVATAR_URL,
      };

      setUser(user?.primaryEmail);
      setCurrentUser(formatUser);
    }
  }, [sessionResponse]);

  return { currentUser, usersList: [] };
};

export default useUserData;
