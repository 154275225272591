import { FunctionComponent, useEffect, useRef, useState } from 'react';

import { Tooltip } from 'components';

import { IAsnStatusEnum } from 'service/generated/graphql';

import { InventoryDepth, OrdersStatus, StatusType } from 'utils/enums';
import { UserStatusType } from 'utils/enums/team';
import { generateSid } from 'utils/functions/generateSid';

type StatusProps = {
  status: string;
  type: string;
  title?: string;
  description?: string;
  isMain?: boolean;
  displayBorder?: boolean;
  className?: string;
  parentClass?: string;
};

export const ordersStatusProperties = {
  [OrdersStatus.Pending]: {
    bg: 'bg-neutrals-20',
    border: '',
    bgBtn: 'bg-primary-60',
    text: 'text-neutrals-80',
    textBtn: 'text-neutrals',
    description: 'Pending',
    ring: 'ring-mvs-border-lighter',
    descriptionTooltip:
      'This status indicates that the order has been successfully registered but there is some missing information.',
  },
  [OrdersStatus.Ordered]: {
    bg: 'bg-neutrals-20',
    border: '',
    bgBtn: 'bg-primary-60',
    text: 'text-neutrals-80',
    textBtn: 'text-neutrals',
    description: 'Ordered',
    ring: 'ring-mvs-border-lighter',
    descriptionTooltip:
      'This status indicates that the order has been successfully registered or received by Endless Commerce and is now prepared for processing.',
  },
  [OrdersStatus.Canceled]: {
    bg: 'bg-danger-10',
    border: '',
    bgBtn: 'bg-neutrals-40',
    text: 'text-danger-60',
    textBtn: 'text-neutrals-80',
    description: 'Canceled',
    ring: 'ring-danger-60',
    descriptionTooltip:
      'The order has been officially cancelled either by the user so the order is not able to be processed in any additional way.',
  },
  [OrdersStatus.Shipped]: {
    bg: 'bg-success-10',
    border: '',
    bgBtn: 'bg-neutrals-40',
    text: 'text-success-60',
    textBtn: 'text-neutrals-100',
    description: 'Shipped',
    ring: 'ring-success-60',
    descriptionTooltip:
      'At this stage, the order has been successfully processed by Endless Commerce or an external shipping service, and all necessary arrangements have been made for its onward journey to the customer.',
  },
  [OrdersStatus.Fulfilled]: {
    bg: 'bg-success-10',
    border: '',
    bgBtn: 'bg-neutrals-40',
    text: 'text-success-60',
    textBtn: 'text-neutrals-100',
    description: 'Fulfilled',
    ring: 'ring-success-60',
    descriptionTooltip: 'Fulfilled',
  },
  [OrdersStatus.Allocated]: {
    bg: 'bg-warning-10',
    border: '',
    bgBtn: 'bg-neutrals-40',
    text: 'text-warning-60',
    textBtn: 'text-neutrals-100',
    description: 'Allocated',
    ring: 'ring-warning-60',
    descriptionTooltip:
      'This status indicates that, at this point, the necessary resources or inventory items for fulfilling the order have been successfully reserved and dedicated to the order.',
  },
  [OrdersStatus.InProgress]: {
    bg: 'bg-primary-10',
    border: '',
    bgBtn: 'bg-primary-60',
    text: 'text-primary-60',
    textBtn: 'text-neutrals',
    description: 'In Progress',
    ring: 'ring-primary-60',
    descriptionTooltip:
      'This transitional state occurs when the order is actively being processed, often involving interactions with external systems.',
  },
  [OrdersStatus.PartiallyShipped]: {
    bg: 'bg-primary-10',
    border: '',
    bgBtn: 'bg-primary-60',
    text: 'text-primary-60',
    textBtn: 'text-neutrals',
    description: 'Partially Shipped',
    ring: 'ring-primary-60',
    descriptionTooltip: 'Partially Shipped',
  },
  [OrdersStatus.Error]: {
    bg: 'bg-yellow-10',
    border: '',
    bgBtn: 'bg-primary-60',
    text: 'text-yellow-60',
    textBtn: 'text-neutrals',
    description: 'Error',
    ring: 'ring-yellow-60',
    descriptionTooltip:
      'This order is missing information and can’t be processed. Please edit the order and try again.',
  },
  [OrdersStatus.OnHold]: {
    bg: 'bg-neutrals-20 border',
    border: '',
    bgBtn: 'bg-primary-60',
    text: 'text-neutrals-80',
    textBtn: 'text-neutrals',
    description: 'On Hold',
    ring: 'ring-mvs-border-lighter',
    descriptionTooltip:
      'This order is currently on hold because it has not been paid yet',
  },
  [OrdersStatus.Draft]: {
    bg: 'bg-neutrals-20 border',
    border: '',
    bgBtn: 'bg-primary-60',
    text: 'text-neutrals-80',
    textBtn: 'text-neutrals',
    description: 'Draft',
    ring: 'ring-mvs-border-lighter',
    descriptionTooltip: 'Draft.',
  },
  __none__: {
    bg: 'bg-transparent',
    text: '',
    description: '',
    ring: '',
    descriptionTooltip: '',
  },
};

export const asnStatusProperties = {
  [IAsnStatusEnum.Incoming]: {
    bg: 'bg-neutrals-20',
    bgBtn: 'bg-primary-60',
    confirmationTitle: 'Mark as Arrived',
    confirmationDescription:
      'This will notify users that the shipment has arrived to the facility, but has not yet been counted or received into inventory.',
    text: 'text-neutrals-80',
    textBtn: 'text-neutrals',
    description: 'Incoming',
    descriptionBtn: 'Mark as arrived',
    descriptionTooltip: 'The ASN was created and has not yet arrived.',
    nextStatus: IAsnStatusEnum.Arrived,
  },
  [IAsnStatusEnum.Receiving]: {
    bg: 'bg-primary-10',
    bgBtn: 'bg-primary-60',
    confirmationTitle: 'Mark as “Closed”',
    confirmationDescription:
      'To mark as “Closed” please select a receiving location for these units.',
    text: 'text-primary-60',
    textBtn: 'text-neutrals',
    description: 'In Receiving',
    descriptionBtn: 'Close asn',
    descriptionTooltip:
      'The shipment is at a Receiving location and is being verified.',
    nextStatus: IAsnStatusEnum.Closed,
  },
  [IAsnStatusEnum.Arrived]: {
    bg: 'bg-primary-10',
    bgBtn: 'bg-primary-60',
    confirmationTitle: 'Start Receiving',
    confirmationDescription:
      'Count the arrived inventory and verify quantities on the next page. The ASN status will move to In Receiving.',
    text: 'text-primary-60',
    textBtn: 'text-neutrals',
    description: 'Arrived',
    descriptionBtn: 'Start receiving',
    descriptionTooltip:
      'The shipment has arrived to the building, but has not yet been physically validated.',
    nextStatus: IAsnStatusEnum.Receiving,
  },
  [IAsnStatusEnum.Closed]: {
    bg: 'bg-success-10',
    bgBtn: 'bg-neutrals-40',
    text: 'text-success-60',
    textBtn: 'text-neutrals-100',
    description: 'Closed',
    descriptionBtn: 'Closed',
    descriptionTooltip:
      'The ASN is closed, with no changes versus what was expected.',
    nextStatus: '',
  },
  [IAsnStatusEnum.Canceled]: {
    bg: 'bg-danger-10',
    bgBtn: 'bg-neutrals-40',
    text: 'text-danger-60',
    textBtn: 'text-neutrals-80',
    description: 'Canceled',
    descriptionBtn: 'Canceled',
    descriptionTooltip: 'The ASN has been canceled.',
    nextStatus: '',
  },
};

export const inventoryStatusProperties = {
  [InventoryDepth.FullStock]: {
    bg: 'bg-success-10',
    bgBtn: 'bg-neutrals-40',
    border: '',
    text: 'text-success-60',
    textBtn: 'text-neutrals-100',
    description: 'Full Stock',
    descriptionBtn: 'Full Stock',
    descriptionTooltip: 'Available units exceed current demand.',
    nextStatus: '',
  },
  [InventoryDepth.LowStock]: {
    bg: 'bg-yellow-10',
    bgBtn: 'bg-primary-60',
    border: '',
    text: 'text-yellow-60',
    textBtn: 'text-neutrals',
    description: 'Low Stock',
    descriptionBtn: 'Low Stock',
    descriptionTooltip: 'Available units are low relative to recent demand.',
  },
  [InventoryDepth.OutOfStock]: {
    bg: 'bg-danger-10',
    bgBtn: 'bg-primary-60',
    border: '',
    text: 'text-danger-60',
    textBtn: 'text-neutrals',
    description: 'Out of Stock',
    descriptionBtn: 'Out of Stock',
    descriptionTooltip: 'There are no units physically in stock.',
  },
  [InventoryDepth.Oversold]: {
    bg: 'bg-purple-10',
    bgBtn: 'bg-purple-60',
    border: '',
    text: 'text-purple-60',
    textBtn: 'text-neutrals',
    description: 'Oversold',
    descriptionBtn: 'Oversold',
    descriptionTooltip: 'Current demand from orders exceeds current inventory',
  },
  __none__: {
    bg: 'bg-transparent',
    text: '',
    description: '',
    descriptionTooltip: '',
  },
};

export const userStatusProperties = {
  [UserStatusType.ACTIVE]: {
    bg: 'bg-success-10',
    bgBtn: 'bg-neutrals-40',
    border: '',
    text: 'text-success-60',
    textBtn: 'text-neutrals-100',
    description: 'Active',
    descriptionBtn: 'Active',
    descriptionTooltip: 'Active User',
    nextStatus: '',
  },
  [UserStatusType.PENDING_VERIFICATION]: {
    bg: 'bg-yellow-10',
    bgBtn: 'bg-primary-60',
    border: '',
    text: 'text-yellow-60',
    textBtn: 'text-neutrals',
    description: 'Pending',
    descriptionBtn: 'Active',
    descriptionTooltip: 'Active User',
    nextStatus: '',
  },
};

const Status: FunctionComponent<StatusProps> = ({
  status,
  type,
  title,
  isMain,
  displayBorder = false,
  className = '',
}) => {
  const [statusStyle, setStatusStyle] = useState({
    bg: 'bg-white',
    text: 'text-white',
    border: 'border-white',
  });
  const [statusProperties, setStatusProperties] = useState({});
  const { current: tooltipId } = useRef(generateSid(10));

  useEffect(() => {
    switch (type) {
      case StatusType.Orders:
        setStatusProperties(ordersStatusProperties);
        break;
      case StatusType.Inventory:
        setStatusProperties(inventoryStatusProperties);
        break;
      case StatusType.Users:
        setStatusProperties(userStatusProperties);
        break;
      default:
        setStatusProperties(asnStatusProperties);
        break;
    }
  }, [type]);

  useEffect(() => {
    statusProperties[status]
      ? setStatusStyle(statusProperties[status])
      : setStatusStyle(statusProperties['__none__']);
  }, [status, statusProperties]);

  const textStatus = () => {
    return (
      <div
        className={`flex text-sm items-center justify-center h-6 rounded font-aeonik fill-current px-2 py-0.5 ${
          statusStyle?.bg
        } ${displayBorder ? statusStyle?.border : ''} ${statusStyle?.text}`}
      >
        <span className={`flex whitespace-nowrap text-sm ${className}`}>
          {statusProperties[status]?.description || title}
        </span>
        {isMain}
      </div>
    );
  };

  return (
    <>
      {isMain && (title || statusProperties[status]?.description) ? (
        <>
          <div
            className="flex"
            data-tooltip-variant="light"
            data-tooltip-id={tooltipId}
          >
            {textStatus()}
          </div>
          <Tooltip
            id={tooltipId}
            place="bottom"
            className="bg-white font-aeonik p-5"
          >
            <div className="p-5 shadow-tooltip group-hover:opacity-100 text-[13px] font-aeonik rounded w-52">
              {statusProperties[status]?.descriptionTooltip}
            </div>
          </Tooltip>
        </>
      ) : (
        <>{textStatus()}</>
      )}
    </>
  );
};

export default Status;
