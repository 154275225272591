import { useLazyQuery, useMutation } from '@apollo/client';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useState } from 'react';

import { sessionActions } from 'store/slices/sessionSlice';

import COMPANIES_DROPDOWN_QUERY from 'api/company';
import { AUTH, authVariables, CLOSE_AUTH } from 'api/session/auth';

import { INITIAL_AUTHORIZED_PATH, LOGIN_PAGE } from 'utils/constants/menu';
import { TrackingEventAction } from 'utils/constants/trackingEvents';

import { Metadata } from 'types/configTypes';
import { FeatureFlag } from 'types/featureFlags';

import { useAppDispatch } from './redux';
import useApplicationData from './useApplicationData';
import useCompanySelector from './useCompanySelector';
import { COOKIES, useCookies } from './useCookies';
import useFeature from './useFeature';
import { useTracking } from './useTracking';

interface useAuthenticationProps {
  activation?: string;
  featureFlags?: FeatureFlag[];
  metadataInitialProps?: Metadata;
}

const oid = process.env.NEXT_PUBLIC_SALESFORCE_OID || '';

const useAuthentication = ({
  featureFlags = [],
}: useAuthenticationProps = {}) => {
  const dispatch = useAppDispatch();
  const { trackEvent, setUser } = useTracking();
  const [registrationResponse, setRegistrationResponse] = useState(false);
  const [getUserAllowedCompanies] = useLazyQuery(COMPANIES_DROPDOWN_QUERY);
  const [authMutation] = useMutation(AUTH);
  const [closeSessionMutation] = useMutation(CLOSE_AUTH);
  const { dispatchFeatureFlags } = useFeature({ featureFlags });
  const { fetchAppData } = useApplicationData();
  const { removeCookie } = useCookies();
  const { selectedCompany, setCompanyValue } = useCompanySelector();

  const handleLogin = async credentials => {
    dispatch(sessionActions.setSessionLoading(true));

    try {
      const response = await authMutation({
        variables: authVariables(credentials),
      });

      if (response.data.signIn) {
        const { token, tokenExpiration } = response.data.signIn;
        Cookies.set('authorization', token, {
          expires: new Date(tokenExpiration),
        });
        Cookies.set('validUntil', tokenExpiration, {
          expires: new Date(tokenExpiration),
        });
        window.location.href = INITIAL_AUTHORIZED_PATH;
      }

      trackEvent(TrackingEventAction.LOGIN);
      dispatchFeatureFlags(featureFlags);
    } catch (error) {
      dispatch(sessionActions.setSessionLoading(false));
    }
  };

  const handleRegistration = async formValues => {
    dispatch(sessionActions.setSessionLoading(true));

    try {
      const salesForceRegistrationValues = {
        oid,
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        email: formValues.email,
        company: '',
      };

      await axios.post(
        '/api/join-wait-list',
        new URLSearchParams(salesForceRegistrationValues)
      );

      setRegistrationResponse(true);
      trackEvent(TrackingEventAction.REGISTRY);
    } finally {
      dispatch(sessionActions.setSessionLoading(false));
    }
  };

  const handleLogout = async () => {
    dispatch(sessionActions.setSessionLoading(true));

    try {
      const response = await closeSessionMutation({
        variables: {
          input: {},
        },
      });

      if (response.data.signOut) {
        trackEvent(TrackingEventAction.LOGOUT);
        setUser(undefined);
        clearCookies();
        window.location.href = LOGIN_PAGE;
      }
    } catch (error) {
      dispatch(sessionActions.setSessionLoading(false));
    }
  };

  const fetchCurrentCompany = async () => {
    const availableCompanies = await getUserAllowedCompanies();
    if (availableCompanies) {
      const companies = availableCompanies?.data?.companies;
      if (companies?.length === 1 && !selectedCompany) {
        setCompanyValue(companies?.[0]?.value);
      }
    }
  };

  const handleValidateSession = async () => {
    try {
      await fetchAppData();
      await fetchCurrentCompany();
      dispatch(sessionActions.setSessionLoading(false));
    } catch {
      await handleLogout();
    }
  };

  const clearCookies = () => {
    removeCookie(COOKIES.authorization);
    removeCookie(COOKIES.companyId);
    removeCookie(COOKIES.brandId);
    removeCookie(COOKIES.validUntil);
  };

  return {
    registrationResponse,
    handleLogin,
    handleLogout,
    handleRegistration,
    handleValidateSession,
  };
};

export default useAuthentication;
