import Link from 'next/link';
import { FunctionComponent, ReactNode } from 'react';

interface SmartLinkProps {
  externalResource?: boolean;
  path: string;
  target?: string;
  children: ReactNode;
  className?: string;
}

const SmartLink: FunctionComponent<SmartLinkProps> = ({
  externalResource,
  path,
  children,
  target = '_blank',
  className = '',
}: SmartLinkProps) => {
  return Boolean(externalResource) ? (
    <a href={path} target={target} className={className}>
      {children}
    </a>
  ) : (
    <Link href={path} className={className} prefetch={false}>
      {children}
    </Link>
  );
};

export default SmartLink;
