import { useMemo } from 'react';

import { IOrderFulfillmentMethodEnum } from 'service/generated/graphql';

import { OrdersStatus } from 'utils/enums';

import { OrderRow } from 'types/ordersTable';

interface useOrdersOperationsProps {
  selectedRows: OrderRow[];
}

const useOrderValidationOperations = ({
  selectedRows,
}: useOrdersOperationsProps) => {
  const canFulfillOrder = () => {
    return (
      selectedRows.length > 0 &&
      selectedRows.every(
        order =>
          (order.orderStatus === OrdersStatus.Ordered ||
            order.orderStatus === OrdersStatus.Allocated) &&
          order.fulfillmentMethod !== IOrderFulfillmentMethodEnum.NonProcessable
      )
    );
  };

  const canAllocateOrder = () => {
    return (
      selectedRows.length > 0 &&
      selectedRows.every(order => order.orderStatus === OrdersStatus.Ordered)
    );
  };

  const canUnAllocateOrder = () => {
    return (
      selectedRows.length > 0 &&
      selectedRows.every(order => order.orderStatus === OrdersStatus.Allocated)
    );
  };

  const canUnFullFillOrder = () => {
    return (
      selectedRows.length > 0 &&
      selectedRows.every(order => order.orderStatus === OrdersStatus.Shipped)
    );
  };

  const isFulfillable = useMemo(() => canFulfillOrder(), [selectedRows]);
  const isAllocated = useMemo(() => canAllocateOrder(), [selectedRows]);
  const isUnAllocated = useMemo(() => canUnAllocateOrder(), [selectedRows]);
  const isUnFulFill = useMemo(() => canUnFullFillOrder(), [selectedRows]);

  return {
    isFulfillable,
    isAllocated,
    isUnAllocated,
    isUnFulFill,
  };
};

export default useOrderValidationOperations;
