import { FunctionComponent } from 'react';

export interface RoundedButtonProps {
  /**
   * Component inside RoundedButton to render
   */
  children: React.ReactNode;
  /**
   * Function to perform when clicked
   */
  onClick: () => void;
  /**
   * Extra class names to apply to the button
   */
  className?: string;
  /**
   * Flag to disable button
   */
  disabled?: boolean;
}

const RoundedButton: FunctionComponent<RoundedButtonProps> = ({
  children,
  onClick,
  className = 'w-45',
  disabled,
}: RoundedButtonProps) => {
  return (
    <button
      type="button"
      className={`${
        disabled ? 'opacity-40' : ''
      } bg-primary-60 text-surface cursor-pointer focus:outline-none ${className} flex justify-center items-center h-10.5 rounded-full`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default RoundedButton;
