import { MouseEvent, FunctionComponent, useEffect, useRef, useState } from 'react'

import ArrowDropdown from '../../assets/icons/arrow-dropdown.svg'

export interface ListItems {
  display: string
  value: number
}
export interface SelectProps {
  /**
   * Description text for the button
   */
  text: string
  /**
   * Selected quantity for the table
   */
  quantity: number
  /**
   * List of items to be displayed in the dropdown
   */
  list: Array<ListItems>
  /**
   * Callback which will be callled after clicking any list item
   */
  onClick: (event: MouseEvent<HTMLDivElement>) => void
  /**
   * Boolean expresion to disable button
   */
  isDisabled?: boolean
  /**
   * Classname for align component
   */
  className?: string
}

const Select: FunctionComponent<SelectProps> = ({
  text,
  quantity,
  isDisabled,
  className,
  list,
  onClick,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const btnDropdownRef = useRef(null)

  const handleClick = (event) => {
    if (btnDropdownRef.current.contains(event.target)) return
    setIsOpen(false)
  }

  const handleCallback = (event) => {
    setIsOpen(false)
    onClick(event)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <div className="relative" ref={btnDropdownRef}>
      <button
        className={`focus:outline-none py-1.5 px-3 flex items-center rounded-2.1xl ${
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } ${
          isOpen ? 'border border-secondary' : 'border border-gray-inactive'
        } ${className || null}`}
        disabled={isDisabled}
        style={{ transition: 'all .15s ease' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={`pr-2.5 text-sm font-aeonik ${
            isOpen ? 'text-secondary' : 'text-secondary-items'
          }`}
        >
          {text}
        </span>
        <span
          className={`mr-1.25 text-sm font-aeonik ${
            isOpen ? 'text-secondary' : 'text-secondary-items'
          }`}
        >
          {quantity}
        </span>
        <ArrowDropdown
          className={`fill-inactive ${isOpen ? 'open-menu' : 'close-menu'}`}
        />
      </button>
      <div
        className={`${
          isOpen ? 'bottom-10' : 'hidden'
        } origin-top-right absolute left-0 -mr-1 rounded-md w-29.25 py-2 shadow-bs-four bg-white`}
      >
        {list.map((item, index) => (
          <div
            className="cursor-pointer flex items-center rounded-lg.5 py-2 px-4 relative whitespace-no-wrap"
            key={`${index}-${item.value}`}
            onClick={handleCallback}
          >
            <div className="flex items-center w-1.2">
              {quantity.toString() === item.display && (
                <span className="rounded-full w-1.2 h-1.2 flex items-center justify-center bg-secondary"></span>
              )}
            </div>
            <span
              className={`${
                quantity.toString() === item.display
                  ? 'text-secondary'
                  : 'text-secondary-items'
              } font-aeonik text-sm ml-3`}
            >
              {item.display}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Select;
